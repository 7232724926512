import React from "react"
import { JesLayout } from "../components/jes-layout"
import { SecondaryHeading, StandardParagraph } from "../components/blocks"
import { Link, useTranslation, Trans } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"

const KontaktPage = ({}) => {
  const { t } = useTranslation()

  return (
    <JesLayout title={t("Kontakt")}>
      <div>
        <SecondaryHeading>
          <Trans>JES Kulturstiftung</Trans>
        </SecondaryHeading>
        <StandardParagraph style={{ padding: "20px 0px" }}>
          <Trans>Adolf-Münzer-Straße 9</Trans>
          <br />
          <Trans>86919 Holzhausen am Ammersee</Trans>
        </StandardParagraph>
        <StandardParagraph style={{ padding: "20px 0px" }}>
          <Trans>+49 (0)8806 924 115</Trans>
          <br />
          <Trans>post@jes-kulturstiftung.de</Trans>
        </StandardParagraph>
      </div>
    </JesLayout>
  )
}

export default KontaktPage
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
